/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import GlobalStyle from "../utils/GlobalStyles";
import { graphql } from "gatsby";

// importing custom components
import CustomHeader from "../components/CustomHeader";
import CustomFooter from "../components/CustomFooter";
import Following from "../containers/FollowContainer";
import Layout from "../components/Layout";
import Img from "gatsby-image";

// importing grid system
import { Container, Row, Col } from "reactstrap";
import SEO from "../seo";

// importing Fonts
import "../../static/fonts/stylesheet.css"

const Hmax = styled.div`
    font-family: Bebas Neue;
    font-size: 54px;
    line-height: 1;
    letter-spacing: 0.96px;
    color: #1b1b1b;
  `;

const Hmid = styled.div`
    font-family: Bebas Neue;
    font-size: 28px;
    line-height: 0.96;
    letter-spacing: 0.96px;
    color: #1b1b1b;
    margin-top: 25px;
    width: 100%;
  `;

const Hmin = styled.div`
    font-family: "PT Mono";
    margin-top: 20px;
    line-height: 1.5;
    color: #333333;
    width: 100%;

    @media (max-width: 576px) {
      // height: 205px;
    }
  `;

const SocialLinksWrapper = styled.div`
  // @media (max-width: 576px) {
  //   width: 327px;
  //   height: 205px;
  // }
   width: 100%;
`;

const UnderLineText = styled.a`
    font-family: "PT Mono";
    font-size: 14px;
    margin-top: 30px;
    color:#333333;
    text-decoration: underline;
  `;

const SplitterLine = styled.div`
    background-color: black;
    height: 1.5px;
    margin-top: 10px;
    width: 100%;
  `;

const Image = styled(Img)`
  width: 100%;
  height: 320px;

  @media (max-width: 576px) {
    width: 327px;
    height: 205px;
  }
`;

const BackgroundImage = styled.div`
  height: 250px;
   @media (max-width: 992px) {
    height: 100px;
  }
   @media (max-width: 576px) {
    height: 50px;
  }
`;

const RowContent = styled(Row)`
  @media (min-width: 768px) {
    padding : 0 100px 0 100px;
    margin-top: 70px;
  }
`;

const Brewery = ({ data }) => {
  const metaData = data.allContentfulPage.nodes[0]
  const breweryData = data.allContentfulPage.nodes[0].widgets[0];
  const { title, sections } = breweryData;

  return (
    <Layout>
      <SEO title={metaData.title} description={metaData.metaDescription} />
      <GlobalStyle />
      <CustomHeader />
      <Container
        fluid
        style={{ width: "100%", backgroundColor: "#F6F0EA" }}
      >
        <Row justify="center" className="justify-content-center">
          <Hmax style={{ marginTop: "105px" }}>{title}</Hmax>
        </Row>
        <RowContent>
          {sections.map(section => (
            <Col
              lg={12}
              xl={6}
              md={12}
              sm={12}
              style={{ marginTop: "2em" }}
            >
              <Image fluid={section.image.fluid} />
              <Hmid>
                {section.title}
              </Hmid>
              <SplitterLine />
              <Hmin>
                {section.description.description}
              </Hmin>
              <SocialLinksWrapper>
                <UnderLineText href={section.facebookUrl} target="_blank">
                  Facebook
                </UnderLineText>
                <UnderLineText href={section.instagramUrl} target="_blank" style={{ marginLeft: "10px" }}>
                  Instagram
                </UnderLineText>
              </SocialLinksWrapper>
            </Col>
          ))}
        </RowContent>
      </Container>
      <BackgroundImage
        style={{
          backgroundImage: `url('/images/breweryBg.svg')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      />
      <Following />
      <CustomFooter />
    </Layout>
  );
};

export default Brewery;

export const query = graphql`
  query {
    allContentfulPage(filter: { identifier: { eq: "brewery" } }) {
      nodes {
        title
        metaDescription
        widgets {
          ... on ContentfulBreweryWidget {
            id
            title
            sections {
              title
              description {
                description
              }
              image {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              facebookUrl
              instagramUrl
            }
          }
        }
      }
    }
  }
`;
